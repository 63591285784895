import { Button } from "@klumpp/tools";
import { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import Setting from "./Setting";

export interface UserSettingsProps {}

export interface UserSettingsState {}

export default class UserSettings extends Component<UserSettingsProps, UserSettingsState> {
	render() {
		return (
			<BasePage title="Persönliche Einstellungen">
				<Setting title="Cookies">
					<Button
						text="Cookie Einstellungen öffnen"
						type="warning"
						onClick={() => {
							window.cookies.openBanner();
						}}
					></Button>
				</Setting>
			</BasePage>
		);
	}
}
