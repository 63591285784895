import { Form, Input, MessageBox, RouterHistory, showNotification } from "@klumpp/tools";
import { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import "./User.scss";

export interface UserProps {
	forcePWChange?: boolean;
}

type Status = "NEUTRAL" | "VALID" | "INVALID";
export interface UserState {
	minimumLength: Status;
	minimumUpperCase: Status;
	minimumLowserCase: Status;
	minimumSpecial: Status;
	minimumNumber: Status;
	samePassword: Status;
	user: any;
}

export default class User extends Component<UserProps, UserState> {
	state = {
		user: {},
		samePassword: "NEUTRAL",
		minimumNumber: "NEUTRAL",
		minimumLength: "NEUTRAL",
		minimumUpperCase: "NEUTRAL",
		minimumLowserCase: "NEUTRAL",
		minimumSpecial: "NEUTRAL",
	} as UserState;

	onPasswordChange = (values: any) => {
		const newPassword = values.newPassword;
		const oldPassword = values.oldPassword;
		const wdhPassword = values.newWdhPassword;

		if (!newPassword && !oldPassword && !wdhPassword) {
			this.setState({
				minimumLength: "NEUTRAL",
				minimumSpecial: "NEUTRAL",
				minimumUpperCase: "NEUTRAL",
				minimumLowserCase: "NEUTRAL",
				minimumNumber: "NEUTRAL",
				samePassword: "NEUTRAL",
			});
			return;
		}

		if (newPassword !== "") {
			if (newPassword.length < 8) {
				this.setState({ minimumLength: "INVALID" });
			} else {
				this.setState({ minimumLength: "VALID" });
			}

			if (newPassword.match(/[a-z]/)) {
				this.setState({ minimumLowserCase: "VALID" });
			} else {
				this.setState({ minimumLowserCase: "INVALID" });
			}

			if (newPassword.match(/[A-Z]/)) {
				this.setState({ minimumUpperCase: "VALID" });
			} else {
				this.setState({ minimumUpperCase: "INVALID" });
			}

			if (newPassword.match(/[0-9]/)) {
				this.setState({ minimumNumber: "VALID" });
			} else {
				this.setState({ minimumNumber: "INVALID" });
			}
			//[$&+,:;=?@#|'<>.-^*()%!]
			if (newPassword.match(/[$&+,:;=?@#|'<>.\-^*()%!]/)) {
				this.setState({ minimumSpecial: "VALID" });
			} else {
				this.setState({ minimumSpecial: "INVALID" });
			}
		}

		if (newPassword !== "" && wdhPassword !== "") {
			if (newPassword === wdhPassword) {
				this.setState({ samePassword: "VALID" });
			} else {
				this.setState({ samePassword: "INVALID" });
			}
		}
	};

	onSubmitPassword = async (values: any, reset: any) => {
		if (
			this.state.samePassword === "VALID" &&
			this.state.minimumLength === "VALID" &&
			this.state.minimumLowserCase === "VALID" &&
			this.state.minimumNumber === "VALID" &&
			this.state.minimumSpecial === "VALID" &&
			this.state.minimumUpperCase === "VALID"
		) {
			if (values.oldPassword !== "" && values.newPassword !== "" && values.newWdhPassword !== "") {
				if (values.oldPassword !== values.newPassword) {
					await window.backend.putData("/user/password", values, true).then(() => {
						RouterHistory.replace("/");
						reset();
					});
				} else {
					showNotification({
						msg: "Ihr neues Passwort sollte nicht das alte Passwort sein.",
						type: "E",
					});
				}
			} else {
				showNotification({ msg: "Bitte füllen sie alle Felder aus.", type: "E" });
			}
		} else {
			showNotification({
				msg: "Bitte prüfen sie alle Passwort Vorausetzungen.",
				type: "E",
			});
		}
	};

	componentDidMount() {
		window.auth.getUser().then((user) => {
			this.setState({ user: user });
		});
	}

	render() {
		if (this.props.forcePWChange) {
			return (
				<BasePage title="Benutzer">
					<MessageBox type="INFO">
						Herzlich Willkommen! Beim ersten Login in Ihren Account müssen sie Ihr Passwort ändern. Daraufhin werden sie automatisch
						weitergeleitet zur Anwendung.
					</MessageBox>
					<div className="personal-user">
						<div className="change-password-fields">
							<Form title="Passwort ändern" onChange={this.onPasswordChange} onSubmit={this.onSubmitPassword}>
								<Input required type="password" name="oldPassword" title="Altes Passwort"></Input>
								<Input required type="password" name="newPassword" title="Neues Passwort"></Input>
								<Input required type="password" name="newWdhPassword" title="Wiederholung"></Input>
								<Input type="submit" defaultValue="Passwort ändern"></Input>
								<Input type="reset" defaultValue="Abbrechen"></Input>
							</Form>
						</div>
						<div className="change-password-validations">
							<div className={`change-password-validations-item ${this.state.minimumLength}`}>Mindestens 8 Zeichen.</div>
							<div className={`change-password-validations-item ${this.state.minimumNumber}`}>Mindestens eine Zahl.</div>
							<div className={`change-password-validations-item ${this.state.minimumLowserCase}`}>Mindestens ein Kleinbuchstabe.</div>
							<div className={`change-password-validations-item ${this.state.minimumUpperCase}`}>Mindestens ein Großbuchstabe.</div>
							<div className={`change-password-validations-item ${this.state.minimumSpecial}`}>Mindestens ein Sonderzeichen.</div>
							<div className={`change-password-validations-item ${this.state.samePassword}`}>
								Das Passwort und das wiederholte Passwort müssen übereinstimmen.
							</div>
						</div>
					</div>
				</BasePage>
			);
		}
		return (
			<BasePage title="Benutzer">
				<div className="personal-user">
					<div className="change-password-fields">
						<Form title="Passwort ändern" onChange={this.onPasswordChange} onSubmit={this.onSubmitPassword}>
							<Input required type="password" name="oldPassword" title="Altes Passwort"></Input>
							<Input required type="password" name="newPassword" title="Neues Passwort"></Input>
							<Input required type="password" name="newWdhPassword" title="Wiederholung"></Input>
							<Input type="submit" defaultValue="Passwort ändern"></Input>
							<Input type="reset" defaultValue="Abbrechen"></Input>
						</Form>
					</div>
					<div className="change-password-validations">
						<div className={`change-password-validations-item ${this.state.minimumLength}`}>Mindestens 8 Zeichen.</div>
						<div className={`change-password-validations-item ${this.state.minimumNumber}`}>Mindestens eine Zahl.</div>
						<div className={`change-password-validations-item ${this.state.minimumLowserCase}`}>Mindestens ein Kleinbuchstabe.</div>
						<div className={`change-password-validations-item ${this.state.minimumUpperCase}`}>Mindestens ein Großbuchstabe.</div>
						<div className={`change-password-validations-item ${this.state.minimumSpecial}`}>Mindestens ein Sonderzeichen.</div>
						<div className={`change-password-validations-item ${this.state.samePassword}`}>
							Das Passwort und das wiederholte Passwort müssen übereinstimmen.
						</div>
					</div>
				</div>
			</BasePage>
		);
	}
}
