import { RouterHistory } from "@klumpp/tools";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<Router history={RouterHistory}>
		<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
		<App history={RouterHistory} />
	</Router>,
	document.getElementById("root")
);

// If you want to start measuring  performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
