import { Button, IconButton, Input, MessageBox, Popover, RouterHistory, Spinner, VerticalNavigation, VerticalNavigationItem } from "@klumpp/tools";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./Dashboard.scss";
import Settings from "../pages/settings/Settings";
import BasePage from "./BasePage";
import Institutions from "../pages/institutions/Institutions";
import Users from "../pages/users/Users";
import Drives from "../pages/drives/Drives";
import DetailDrive from "../pages/drives/DetailDrive";
import RequestDrive from "../pages/drives/RequestDrive";
import UserPage from "../pages/users/User";
import Logs from "../pages/logs/Logs";
import UserSettings from "../pages/settings/UserSettings";
import Locations from "../pages/locations/Locations";
import PrefillOptions from "../pages/prefillOptions/PrefillOptions";

export interface DashboardProps {}

interface User {
	picture: string;
	email: string;
	name: string;
	type: "sachbearbeiter" | "admin" | "institutionuser" | "";
}

interface Institution {
	id: number;
	name: string;
	plz: string;
	city: string;
	housenumber: string;
	street: string;
}

export interface DashboardState {
	user: User;
	loading: boolean;
	userLoading: boolean;
	socketLoading: boolean;
	institutions: Institution[];
	selectedInstitution: number;
	navOpen: boolean;
	forcePWChange: boolean;
}

export default class Dashboard extends Component<DashboardProps, DashboardState> {
	state = {
		loading: false,
		socketLoading: true,
		userLoading: true,
		selectedInstitution: -1,
		institutions: [],
		user: { picture: "", email: "", name: "", type: "" },
		navOpen: false,
		forcePWChange: false,
	} as DashboardState;

	userPopover = React.createRef<Popover>();
	institutionInputRef = React.createRef<Input>();
	locListener: any;

	componentDidMount() {
		window.auth.isAuthenticated().then((authenticated) => {
			if (!authenticated) {
				window.auth.loginWithRedirect(window.location.href.replace(window.location.origin, ""));
				return;
			}
			window.auth.getUser().then(async (user: any) => {
				if (!user) {
					window.auth.loginWithRedirect(window.location.pathname + window.location.hash);
				}

				window.auth.getAccessToken(window.location.pathname + window.location.hash).then((token) => {
					window.backend.socket()?.on("authenticated", () => {
						this.setState({ socketLoading: false });
					});
					window.backend.socket()?.on("successfullyConnected", () => {
						window.auth.getAccessToken(window.location.pathname + window.location.hash).then((token) => {
							window.backend.socket()?.emit("authenticate", { jwt: token }, true);
						});
					});
					window.backend.socket()?.emit("authenticate", { jwt: token }, true);
				});

				const userType = await this.loadUserType();

				user.type = userType as any;

				if (this.state.forcePWChange) {
					RouterHistory.replace("/dashboard/personalUser");
				} else {
					if (window.location.pathname === "/dashboard") {
						if (user.type === "admin") {
							RouterHistory.replace("/dashboard/institutions");
						} else if (user.type === "institutionuser") {
							RouterHistory.replace("/dashboard/drives");
						} else if (user.type === "sachbearbeiter") {
							RouterHistory.replace("/dashboard/drives");
						}
					}
				}

				this.setState({ user: user }, () => {
					if (user.type === "institutionuser") {
						const currentHash = Number(window.location.hash.replace("#", "") === "" ? -1 : window.location.hash.replace("#", ""));

						if (currentHash !== -1) {
							this.setState({ selectedInstitution: currentHash }, () => {
								this.loadInstitutions();
							});
						} else {
							this.loadInstitutions();
						}
						this.locListener = RouterHistory.listen((location) => {
							if (window.location.hash === "") {
								window.location.hash = this.institutionInputRef.current?.getValue();
							} else {
								const hash = window.location.hash.replace("#", "");
								const id = Number(hash === "" ? -1 : hash);
								if (this.state.selectedInstitution !== id) {
									this.setState({ selectedInstitution: id });
								}
							}
						});
					}
				});
			});
		});
	}

	componentWillUnmount() {
		this.locListener && this.locListener();
	}

	loadUserType = async () => {
		return new Promise<string>((resolve, reject) => {
			this.setState({ userLoading: true }, () => {
				window.backend.getData("/user/type", true).then((type) => {
					this.setState({ userLoading: false, forcePWChange: type.forcePWChange ? true : false }, () => {
						resolve(type.type);
					});
				});
			});
		});
	};

	loadInstitutions = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData("/institutions", true).then((institutions: Institution[]) => {
				let newSelected = this.state.selectedInstitution;
				if (institutions.length !== 0) {
					if (newSelected === -1) {
						newSelected = institutions[0].id;
					} else {
						const item = institutions.find((institution) => institution.id === newSelected);
						if (!item) {
							newSelected = institutions[0].id;
						}
					}
				} else {
					newSelected = -1;
				}

				this.setState(
					{
						loading: false,
						institutions: institutions,
						selectedInstitution: newSelected,
					},
					() => {
						window.location.hash = `${newSelected}`;
					}
				);
			});
		});
	};

	institutionChanged = () => {
		const value = this.institutionInputRef.current?.getValue();
		if (value !== "") {
			window.location.hash = value;
		}
	};

	closeNav = () => {
		if (this.state.navOpen) {
			this.setState({ navOpen: false });
		}
	};

	getNavigationItems = () => {
		if (this.state.forcePWChange) return [];
		let items: any[] = [];
		if (this.state.user.type === "admin") {
			items = [
				<VerticalNavigationItem
					key={1}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="business"
					to="/dashboard/institutions"
				></VerticalNavigationItem>,
				<VerticalNavigationItem onClick={this.closeNav} history={RouterHistory} icon="people" to="/dashboard/users"></VerticalNavigationItem>,
				<VerticalNavigationItem
					key={2}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="other_houses"
					to="/dashboard/locations"
				></VerticalNavigationItem>,
				<VerticalNavigationItem
					key={2}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="text_fields"
					to="/dashboard/prefillOptions"
				></VerticalNavigationItem>,
				<VerticalNavigationItem
					key={3}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="settings"
					to="/dashboard/settings"
				></VerticalNavigationItem>,
			];
		}
		if (this.state.user.type === "institutionuser") {
			items = [
				<VerticalNavigationItem
					key={1}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="drive_eta"
					to="/dashboard/drives"
				></VerticalNavigationItem>,

				<VerticalNavigationItem
					key={2}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="fiber_new"
					to="/dashboard/driveform"
				></VerticalNavigationItem>,
			];
		}
		if (this.state.user.type === "sachbearbeiter") {
			items = [
				<VerticalNavigationItem
					key={1}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="drive_eta"
					to="/dashboard/drives"
				></VerticalNavigationItem>,
				<VerticalNavigationItem
					key={2}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="archive"
					to="/dashboard/archive"
				></VerticalNavigationItem>,
				<VerticalNavigationItem
					key={3}
					onClick={this.closeNav}
					history={RouterHistory}
					icon="change_history"
					to="/dashboard/logs"
				></VerticalNavigationItem>,
			];
		}

		return items;
	};

	getSwitch = () => {
		if (this.state.forcePWChange) {
			return (
				<Switch>
					<Route exact path="/dashboard/personalUser" render={() => <UserPage forcePWChange></UserPage>} />
				</Switch>
			);
		}

		if (this.state.user.type === "admin") {
			return (
				<Switch>
					<Route exact path="/dashboard/institutions" component={Institutions} />
					<Route exact path="/dashboard/users" component={Users} />
					<Route exact path="/dashboard/locations" component={Locations} />
					<Route exact path="/dashboard/settings" component={Settings} />
					<Route exact path="/dashboard/personalUser" component={UserPage} />
					<Route exact path="/dashboard/personalSettings" component={UserSettings} />
					<Route exact path="/dashboard/prefillOptions" component={PrefillOptions} />
					<Route
						path="/dashboard/*"
						render={() => {
							RouterHistory.push("/");
							return <></>;
						}}
					></Route>
				</Switch>
			);
		}
		if (this.state.institutions.length !== 0 && this.state.user.type === "institutionuser") {
			return (
				<Switch>
					<Route exact path="/dashboard/drives" component={Drives} />
					<Route exact path="/dashboard/drive" component={DetailDrive} />
					<Route exact path="/dashboard/driveform" component={RequestDrive} />
					<Route exact path="/dashboard/personalUser" component={UserPage} />
					<Route exact path="/dashboard/personalSettings" component={UserSettings} />
				</Switch>
			);
		}
		if (this.state.user.type === "sachbearbeiter") {
			return (
				<Switch>
					<Route exact path="/dashboard/drives" render={() => <Drives all></Drives>} />
					<Route exact path="/dashboard/archive" render={() => <Drives archived></Drives>} />
					<Route exact path="/dashboard/drive" component={DetailDrive} />
					<Route exact path="/dashboard/logs" component={Logs} />
					<Route exact path="/dashboard/personalUser" component={UserPage} />
					<Route exact path="/dashboard/personalSettings" component={UserSettings} />
				</Switch>
			);
		}
		if (this.state.institutions.length === 0 && this.state.user.type === "institutionuser") {
			return (
				<Switch>
					<Route
						exact
						path="/dashboard/drives"
						render={() => {
							return (
								<BasePage title="Fahrtenübersicht">
									<MessageBox type="ERROR">Bitte wählen sie eine Institution aus!</MessageBox>
								</BasePage>
							);
						}}
					/>
					<Route exact path="/dashboard/personalUser" component={UserPage} />
					<Route exact path="/dashboard/personalSettings" component={UserSettings} />
				</Switch>
			);
		}
	};

	render() {
		if (this.state.loading || this.state.socketLoading || this.state.userLoading) return <Spinner></Spinner>;
		return (
			<div className={["dashboard", this.state.navOpen ? "nav-open" : ""].join(" ")}>
				<div className="dashboard-top">
					<div className="dashboard-top-start">
						<IconButton
							icon="menu"
							type="transparent"
							className="dashboard-nav-button"
							onClick={() => this.setState({ navOpen: !this.state.navOpen })}
						></IconButton>
						{this.state.user.type === "institutionuser" && (
							<Input
								ref={this.institutionInputRef}
								onChange={this.institutionChanged}
								icon="keyboard_arrow_down"
								required
								name="institution"
								type="select"
								defaultValue={this.state.selectedInstitution}
								options={this.state.institutions.map((institution) => {
									return { value: institution.id, label: institution.name };
								})}
								placeholder="Bitte wählen sie eine Institution aus."
							></Input>
						)}
					</div>
					<div className="dashboard-top-end">
						<div className="dashboard-top-user" id="dashboard-user">
							{this.state.user && (
								<div
									className="dashboard-top-user-image"
									onClick={() => {
										this.userPopover.current?.toggle();
									}}
								>
									<img src={this.state.user.picture} alt="User profile"></img>
								</div>
							)}
							<Popover parentId="dashboard-user" ref={this.userPopover} right className="dashboard-top-user-popup">
								<div className="user-header">
									<h2 className="user-name">{this.state.user.name}</h2>
									<p className="user-email">{this.state.user.email}</p>
								</div>
								<div className="user-content">
									<Button
										text="Mein Profil"
										onClick={() => {
											RouterHistory.push("/dashboard/personalUser");
											this.userPopover.current?.close();
										}}
									></Button>
									<Button
										text="Impressum"
										onClick={() => {
											window.open("https://www.asb-kh.de/impressum");
											this.userPopover.current?.close();
										}}
									></Button>
									<Button
										text="Datenschutz"
										onClick={() => {
											window.open("https://www.asb-kh.de/datenschutz");
											this.userPopover.current?.close();
										}}
									></Button>
									<Button
										text="Einstellungen"
										onClick={() => {
											this.userPopover.current?.close();
											RouterHistory.push("/dashboard/personalSettings");
										}}
									></Button>
								</div>
							</Popover>
						</div>
					</div>
				</div>
				<div className="dashboard-dub">{<img src="/logo.svg" alt="Logo"></img>}</div>
				<div className="dashboard-left">
					<VerticalNavigation>
						{this.getNavigationItems()}
						<VerticalNavigationItem history={RouterHistory} icon="logout" to="/logout" position="BOTTOM"></VerticalNavigationItem>
					</VerticalNavigation>
				</div>
				<div className="dashboard-content">{this.getSwitch()}</div>
			</div>
		);
	}
}
