import React, { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import "./Settings.scss";

export interface SettingsProps {}

export interface SettingsState {}

export default class Settings extends Component<SettingsProps, SettingsState> {
	state = {} as SettingsState;
	componentDidMount() {}

	render() {
		return <BasePage title="Allgemeine Einstellungen"></BasePage>;
	}
}
