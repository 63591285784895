import {
	Button,
	Cell,
	Dialog,
	Form,
	HeaderCell,
	Input,
	OwnTable,
	Spinner,
	SwipeContainer,
	SwipePage,
	TableBody,
	TableHeader,
	TableRow,
} from "@klumpp/tools";
import React, { Component } from "react";
import "./Locations.scss";
import BasePage from "../../dashboard/BasePage";
import { Institution, Location } from "../../../types/ObjectTypes";

export interface LocationsProps {}

export interface LocationsState {
	locations: Location[];
	locationsLoading: boolean;
	editLocation?: Location;
	selectedInstitution: number;
	institutions: Institution[];
	loading: boolean;
}

export default class Locations extends Component<LocationsProps, LocationsState> {
	state = { locations: [], locationsLoading: true, selectedInstitution: -1, institutions: [], loading: true } as LocationsState;
	addDialogRef = React.createRef<Dialog>();
	swipeRef = React.createRef<SwipeContainer>();
	editDialogRef = React.createRef<Dialog>();
	loadingDialogRef = React.createRef<Dialog>();
	refDialogDeletion = React.createRef<Dialog>();
	fileInputRef: React.RefObject<Input> = React.createRef<Input>();
	institutionInputRef: React.RefObject<Input> = React.createRef<Input>();

	loadLocations = (load: boolean = true) => {
		this.setState({ locationsLoading: load }, () => {
			window.backend.getData(`/locations/${this.state.selectedInstitution}`, true).then((locations: any) => {
				this.setState({
					locations: locations,
					locationsLoading: false,
				});
			});
		});
	};

	deleteAllLocations = () => {
		window.backend.deleteData(`/locations/${this.state.selectedInstitution}/all`, {}, true).then(() => {
			this.loadLocations(false);
			this.refDialogDeletion.current?.close();
		});
	};

	openEditDialog = (values: any) => {
		this.setState(
			{
				editLocation: {
					id: values[0],
					name: values[1],
					city: values[2],
					plz: values[3],
					street: values[4],
					houseNumber: values[5],
					telephone: values[6],
					searchterm: values[7],
				},
			},
			() => {
				this.editDialogRef.current?.open();
			}
		);
	};

	createLocation = (values: any, reset: any) => {
		values.institutionId = this.state.selectedInstitution;
		window.backend.postData("/locations", values, true).then(() => {
			reset();
			this.loadLocations(false);
			this.addDialogRef.current?.close();
		});
	};

	updateLocation = (values: any, reset: any) => {
		window.backend.putData(`/locations/${this.state.editLocation?.id}`, values, true).then(() => {
			reset();
			this.loadLocations(false);
			this.editDialogRef.current?.close();
		});
	};

	componentDidMount() {
		this.loadInstitutions();
	}

	importFile = (values: any, reset: any) => {
		const files = values.file as FileList;
		const file = files[0];

		const formData = new FormData();
		formData.append("file", file);
		this.addDialogRef.current?.close();
		this.loadingDialogRef.current?.open();
		window.backend
			.postData(`/locations/import/${this.state.selectedInstitution}`, formData, true)
			.then(() => {
				this.loadingDialogRef.current?.close();
				this.loadLocations(false);
				reset();
				this.swipeRef.current?.setPage(0);
			})
			.catch(() => {
				this.loadLocations(false);
				this.loadingDialogRef.current?.close();
				reset();
				this.swipeRef.current?.setPage(0);
			});
	};

	deleteLocation = (values: any[]) => {
		window.backend.deleteData(`/locations/${values[0]}`, {}, true).then(() => {
			this.loadLocations(false);
		});
	};

	institutionChanged = () => {
		const value = this.institutionInputRef.current?.getValue();
		if (value !== "" && Number(value) !== this.state.selectedInstitution) {
			this.setState({ selectedInstitution: Number(value) }, () => {
				this.loadLocations();
			});
		}
	};

	loadInstitutions = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData("/institutions", true).then((institutions) => {
				this.setState({ loading: false, institutions });
			});
		});
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;

		return (
			<BasePage title="Orte">
				<Dialog ref={this.loadingDialogRef} title="Bitte warten, der Vorgang kann ein paar Minuten dauern.">
					<Spinner></Spinner>
				</Dialog>
				<Dialog ref={this.addDialogRef} title="Ort Anlegen" closeable>
					<SwipeContainer ref={this.swipeRef}>
						<SwipePage>
							<div className="locations-swipe-add">
								<Button
									onClick={() => {
										this.swipeRef.current?.nextPage();
									}}
									text="Datei importieren"
								></Button>
								<Form
									onSubmit={this.createLocation}
									onReset={() => {
										this.addDialogRef.current?.close();
									}}
								>
									<Input required title="Name" type="text" name="name" placeholder="Name"></Input>
									<Input required title="Ort" messagesAsTooltip maxWidth="200px" type="text" name="city" placeholder="Ort"></Input>
									<Input
										required
										title="PLZ"
										messagesAsTooltip
										maxWidth="100px"
										type="text"
										name="plz"
										placeholder="Postleitzahl"
									></Input>
									<Input
										required
										title="Straße"
										messagesAsTooltip
										maxWidth="200px"
										type="text"
										name="street"
										placeholder="Straße"
									></Input>
									<Input
										required
										title="Hausnr."
										messagesAsTooltip
										maxWidth="100px"
										type="text"
										name="houseNumber"
										placeholder="Hausnummer"
									></Input>
									<Input title="Telefonnr." type="tel" name="telephone" placeholder="Telefonnummer"></Input>
									<Input title="Suchbegriff" type="text" name="searchterm" placeholder="Suchbegriff"></Input>
									<Input type="reset" defaultValue="Abbrechen"></Input>
									<Input type="submit" defaultValue="Speichern"></Input>
								</Form>
							</div>
						</SwipePage>
						<SwipePage>
							<div className="locations-swipe-file">
								<p>Wählen Sie eine Datei aus, in der Orte angelegt sind, um diese zu importieren.</p>
								<Form
									onSubmit={this.importFile}
									onReset={() => {
										this.swipeRef.current?.prevPage();
									}}
								>
									<Input
										required
										ref={this.fileInputRef}
										accept=".xlsx"
										title="Datei"
										type="file"
										name="file"
										placeholder="Datei auswählen"
									></Input>
									<Input type="reset" defaultValue="Zurück"></Input>
									<Input type="submit" defaultValue="Importieren"></Input>
								</Form>
							</div>
						</SwipePage>
					</SwipeContainer>
				</Dialog>
				<Dialog ref={this.editDialogRef} title="Ort Bearbeiten" closeable>
					{!this.state.editLocation && <Spinner></Spinner>}
					{this.state.editLocation && (
						<Form
							onSubmit={this.updateLocation}
							onReset={() => {
								this.editDialogRef.current?.close();
							}}
						>
							<Input
								required
								defaultValue={this.state.editLocation?.name}
								title="Name"
								type="text"
								name="name"
								placeholder="Name"
							></Input>
							<Input
								required
								defaultValue={this.state.editLocation?.city}
								title="Ort"
								maxWidth="200px"
								type="text"
								name="city"
								messagesAsTooltip
								placeholder="Ort"
							></Input>
							<Input
								required
								defaultValue={this.state.editLocation?.plz}
								title="PLZ"
								maxWidth="100px"
								type="text"
								name="plz"
								messagesAsTooltip
								placeholder="Postleitzahl"
							></Input>
							<Input
								required
								defaultValue={this.state.editLocation?.street}
								title="Straße"
								maxWidth="200px"
								type="text"
								name="street"
								messagesAsTooltip
								placeholder="Straße"
							></Input>
							<Input
								required
								defaultValue={this.state.editLocation?.houseNumber}
								title="Hausnr."
								maxWidth="100px"
								type="text"
								name="houseNumber"
								messagesAsTooltip
								placeholder="Hausnummer"
							></Input>
							<Input
								defaultValue={this.state.editLocation?.telephone}
								title="Telefonnr."
								type="tel"
								name="telephone"
								placeholder="Telefonnummer"
							></Input>
							<Input
								defaultValue={this.state.editLocation?.searchterm}
								title="Suchbegriff"
								type="text"
								name="searchterm"
								placeholder="Suchbegriff"
							></Input>
							<Input type="reset" defaultValue="Abbrechen"></Input>
							<Input type="submit" defaultValue="Speichern"></Input>
						</Form>
					)}
				</Dialog>
				<div className="locations">
					<div className="locations-institution-select">
						<Input
							required
							ref={this.institutionInputRef}
							onChange={this.institutionChanged}
							icon="keyboard_arrow_down"
							name="institution"
							type="select"
							defaultValue={this.state.selectedInstitution}
							options={this.state.institutions.map((institution) => {
								return { value: institution.id, label: institution.name };
							})}
							placeholder="Bitte wählen sie eine Institution aus."
						></Input>
					</div>
					<div className="locations-table">
						{this.state.selectedInstitution !== -1 && this.state.locationsLoading && <Spinner></Spinner>}
						{this.state.selectedInstitution !== -1 && !this.state.locationsLoading && (
							<OwnTable
								propertyButtons={[
									{ label: "Bearbeiten", onClick: this.openEditDialog },
									{ label: "Löschen", onClick: this.deleteLocation },
								]}
								searchable
								searchPlaceholder="Suchen..."
								addButtonText="Anlegen"
								noDataText="Keine Daten"
								onAdd={() => {
									this.addDialogRef.current?.open();
								}}
							>
								<TableHeader>
									<HeaderCell>Name</HeaderCell>
									<HeaderCell>Ort</HeaderCell>
									<HeaderCell>PLZ</HeaderCell>
									<HeaderCell>Straße</HeaderCell>
									<HeaderCell>Nr.</HeaderCell>
									<HeaderCell>Tel. Nr.</HeaderCell>
									<HeaderCell>Suchbegriff</HeaderCell>
								</TableHeader>
								<TableBody>
									{this.state.locations.map((location, index) => {
										return (
											<TableRow key={index}>
												<Cell notVisible>{location.id}</Cell>
												<Cell>{location.name}</Cell>
												<Cell>{location.city}</Cell>
												<Cell>{location.plz}</Cell>
												<Cell>{location.street}</Cell>
												<Cell>{location.houseNumber}</Cell>
												<Cell>{location.telephone}</Cell>
												<Cell>{location.searchterm}</Cell>
											</TableRow>
										);
									})}
								</TableBody>
							</OwnTable>
						)}
					</div>
					<Dialog ref={this.refDialogDeletion} title="Löschen - Bestätigung">
						<p>Sollen wirklich alle Orte unwiederruflich gelöscht werden?</p>
						<div className="locations-delete-footer">
							<Button text="Abbrechen" onClick={() => this.refDialogDeletion.current?.close()} type="success"></Button>
							<Button text="Löschen" type="error" onClick={this.deleteAllLocations}></Button>
						</div>
					</Dialog>
					<div className="locations-footer">
						{this.state.selectedInstitution !== -1 && !this.state.locationsLoading && (
							<Button type="error" onClick={() => this.refDialogDeletion.current?.open()} text="Alle Löschen"></Button>
						)}
					</div>
				</div>
			</BasePage>
		);
	}
}
