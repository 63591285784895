import {
	Button,
	Cell,
	Dialog,
	Form,
	HeaderCell,
	IconButton,
	Input,
	MessageBox,
	OwnTable,
	RouterHistory,
	Spinner,
	TableBody,
	TableHeader,
	TableRow,
} from "@klumpp/tools";
import React, { Component } from "react";
import { Drive, Institution, Location, UserType } from "../../../types/ObjectTypes";

export interface DriveFormProps {
	onSubmit?: () => void;
	driveId?: number;
	detail?: boolean;
}

export interface DriveFormState {
	editMode: boolean;
	drive: Drive;
	loading: boolean;
	userTypeLoading: boolean;
	userType?: UserType;
	institution?: Institution;
	institutionLoading: boolean;
	locations: Location[];
	locationsLoading: boolean;
	prefillOptions: any;
}

export default class DriveForm extends Component<DriveFormProps, DriveFormState> {
	refPatient = React.createRef<Form>();
	refCaller = React.createRef<Form>();
	refPickup = React.createRef<Form>();
	refTarget = React.createRef<Form>();
	refTransport = React.createRef<Form>();
	requestChangeRef = React.createRef<Dialog>();
	declineDriveRef = React.createRef<Dialog>();
	stornoDriveRef = React.createRef<Dialog>();
	locationDialogRef = React.createRef<Dialog>();

	locListener?: any;
	state = {
		editMode: false,
		drive: {} as Drive,
		loading: true,
		userTypeLoading: true,
		institutionLoading: true,
		locations: [],
		locationsLoading: true,
		prefillOptions: {},
	} as any;
	validate = () => {
		const a = this.refPatient.current?.validateForm();
		const b = this.refCaller.current?.validateForm();
		const c = this.refPickup.current?.validateForm();
		const d = this.refTarget.current?.validateForm();
		const e = this.refTransport.current?.validateForm();

		if (a && b && c && d && e) {
			return true;
		} else {
			return false;
		}
	};

	loadDrive = () => {
		if (this.props.driveId && this.props.driveId !== -1) {
			window.backend.getData(`/drive/${this.props.driveId}`, true).then((drive) => {
				this.setState({ drive: drive, loading: false, institutionLoading: true }, () => {
					this.loadInstitution(this.state.drive.institutionId);
				});
			});
		} else if (!this.props.driveId) {
			this.setState({ loading: false });
		}
	};

	componentDidMount() {
		if (this.props.detail && !this.props.driveId) {
			const hash = window.location.hash.replace("#", "");
			RouterHistory.replace(`/dashboard/drives#${hash}`);
			return;
		}
		window.backend.getData("/user/type", true).then((user) => {
			this.setState({ userType: user.type, userTypeLoading: false }, () => {
				this.state.userType === "institutionuser"
					? this.loadInstitution(window.location.hash.replace("#", ""))
					: this.setState({ institutionLoading: false });
			});
		});

		this.registerLocListener();

		this.loadDrive();
	}

	registerLocListener = () => {
		this.locListener = RouterHistory.listen((location: any) => {
			if (this.props.driveId && (location.pathname as string) !== "/dashboard/drive") return;
			const hash = location.hash.replace("#", "");
			if (this.props.driveId) {
				this.navToDrive();
				return;
			}
			if (this.state.institution?.id !== hash) {
				this.loadInstitution(hash);
				this.loadPrefillOptions(hash);
			}
		});
	};

	componentWillUnmount() {
		this.unregisterLocListener();
	}

	unregisterLocListener = () => {
		this.locListener && this.locListener();
		this.locListener = undefined;
	};

	componentDidUpdate(props: DriveFormProps, state: DriveFormState) {
		if (!this.locListener) this.registerLocListener();

		if (props.driveId !== this.props.driveId) {
			this.loadDrive();
		}
	}

	getValues = () => {
		const values = {
			...this.refCaller.current?.getInputValues(),
			...this.refPatient.current?.getInputValues(),
			...this.refPickup.current?.getInputValues(),
			...this.refTarget.current?.getInputValues(),
			...this.refTransport.current?.getInputValues(),
		};

		return values;
	};

	reset = () => {
		this.loadPrefillOptions(this.state.institution.id, () => {
			this.refCaller.current?.resetInputs();
			this.refPatient.current?.resetInputs();
			this.refPickup.current?.resetInputs();
			this.refTarget.current?.resetInputs();
			this.refTransport.current?.resetInputs();
		});
	};

	storno = (values: any) => {
		window.backend.deleteData(`/drive/${this.props.driveId}`, { comment: values.comment }, true).then(() => {
			this.navToDrive();
		});
	};

	navToDrive = () => {
		this.unregisterLocListener();
		const hash = window.location.hash.replace("#", "");
		RouterHistory.replace(`/dashboard/drives#${hash}`);
	};

	cancelEdit = () => {
		this.reset();
		this.setState({ editMode: false });
	};

	accept = () => {
		window.backend.getData(`/drive/accept/${this.props.driveId}`, true).then(() => this.navToDrive());
	};

	decline = (values: any) => {
		window.backend.postData(`/drive/decline/${this.props.driveId}`, { comment: values.comment }, true).then(() => this.navToDrive());
	};

	loadInstitution = (id: any) => {
		if (id && id !== -1) {
			window.backend.getData(`/institutions/${id}`, true).then((institution: any) => {
				this.setState({ institution, institutionLoading: false });
			});
			this.loadPrefillOptions(id);
		}
	};

	loadPrefillOptions = (id: any, cb?: any) => {
		if (id && id !== -1 && !this.props.driveId) {
			window.backend.getData(`/institutions/driveFormPrefillOptions/${id}`, true).then((prefillOptions) => {
				this.setState({ prefillOptions }, cb);
			});
		}
	};

	update = () => {
		if (this.validate()) {
			const data = this.getValues();
			this.setState({ loading: true }, () => {
				window.backend
					.putData(`/drive/${this.props.driveId}`, data, true)
					.then(() => {
						this.setState({ editMode: false });
					})
					.finally(() => {
						this.loadDrive();
					});
			});
		}
	};

	requestChange = (values: any) => {
		const comment = values.comment;

		window.backend.postData(`/drives/${this.props.driveId}/requestChange`, { comment: comment }, true).then(() => {
			this.navToDrive();
		});
	};

	locationFrom?: string;
	openLocationDialog = (from: "pickup" | "target") => {
		this.locationFrom = from;
		if (this.state.locations.length === 0) {
			window.backend.getData(`/locations/${this.state.institution.id}`, true).then((locations) => {
				this.setState({ locations: locations, locationsLoading: false });
			});
		}

		this.locationDialogRef.current?.open();
	};

	onLocationRowClicked = (values: any) => {
		const prefillOptions = this.state.prefillOptions;
		if (this.locationFrom === "pickup") {
			prefillOptions.pickupStation = values[1];
			prefillOptions.pickupCity = values[2];
			prefillOptions.pickupPostcode = values[3];
			prefillOptions.pickupStreet = values[4];
			prefillOptions.pickupHousenumber = values[5];
		} else {
			prefillOptions.targetStation = values[1];
			prefillOptions.targetCity = values[2];
			prefillOptions.targetPostcode = values[3];
			prefillOptions.targetStreet = values[4];
			prefillOptions.targetHousenumber = values[5];
		}
		this.setState(
			{
				prefillOptions,
			},
			() => {
				this.locationDialogRef.current?.close();
			}
		);
	};

	copyPickupToPatient = () => {
		const values = this.refPickup.current?.getInputValues();
		const prefillOptions = this.state.prefillOptions;
		prefillOptions.patientCity = values.pickupCity;
		prefillOptions.patientPostcode = values.pickupPostcode;
		prefillOptions.patientStreet = values.pickupStreet;
		prefillOptions.patientHousenumber = values.pickupHousenumber;
		this.setState({
			prefillOptions,
		});
	};

	copyPatientToTarget = () => {
		const values = this.refPatient.current?.getInputValues();
		const prefillOptions = this.state.prefillOptions;
		prefillOptions.targetCity = values.patientCity;
		prefillOptions.targetPostcode = values.patientPostCode;
		prefillOptions.targetStreet = values.patientStreet;
		prefillOptions.targetHousenumber = values.patientHousenumber;
		prefillOptions.targetStation = " ";
		this.setState(
			{
				prefillOptions,
			},
			() => {}
		);
	};

	render() {
		const readonly = this.props.driveId && !this.state.editMode ? true : false;
		if (this.state.institutionLoading || this.state.loading || this.state.userTypeLoading || this.props.driveId === -1)
			return <Spinner></Spinner>;
		return (
			<div className="drive-form">
				<Dialog ref={this.locationDialogRef} closeable title="Ort-Auswahl">
					{this.state.locationsLoading && <Spinner></Spinner>}
					{!this.state.locationsLoading && (
						<OwnTable
							onRowClicked={this.onLocationRowClicked}
							clickable
							searchable
							searchPlaceholder="Suchen..."
							noDataText="Keine Daten"
						>
							<TableHeader>
								<HeaderCell>Name</HeaderCell>
								<HeaderCell>Ort</HeaderCell>
								<HeaderCell>PLZ</HeaderCell>
								<HeaderCell>Straße</HeaderCell>
								<HeaderCell>Nr.</HeaderCell>
								<HeaderCell>Tel. Nr.</HeaderCell>
								<HeaderCell>Suchbegriff</HeaderCell>
							</TableHeader>
							<TableBody>
								{(this.state.locations as Location[]).map((location, index) => {
									return (
										<TableRow key={index}>
											<Cell notVisible>{location.id}</Cell>
											<Cell>{location.name}</Cell>
											<Cell>{location.city}</Cell>
											<Cell>{location.plz}</Cell>
											<Cell>{location.street}</Cell>
											<Cell>{location.houseNumber}</Cell>
											<Cell>{location.telephone}</Cell>
											<Cell>{location.searchterm}</Cell>
										</TableRow>
									);
								})}
							</TableBody>
						</OwnTable>
					)}
				</Dialog>
				{this.state.drive.state === "changerequested" && (
					<MessageBox className="message" type="WARNING">
						Für diese Fahrt wurde eine Änderung angefragt. <br></br>
						<br></br>Kommentar des Sachbearbeiters: {this.state.drive.comment}
					</MessageBox>
				)}
				{this.state.drive.state === "declined" && (
					<MessageBox className="message" type="ERROR">
						Diese Fahrt wurde nicht genehmigt. <br></br>
						<br></br>Kommentar des Sachbearbeiters: {this.state.drive.comment}
					</MessageBox>
				)}
				<Dialog ref={this.requestChangeRef} title="Änderung der Fahrt anfragen">
					<div className="change-request-dlg">
						<p>Bitte geben Sie einen Kommentar an, um die Änderung anzufragen.</p>
						<Form
							onSubmit={this.requestChange}
							onReset={() => {
								this.requestChangeRef.current?.close();
							}}
						>
							<Input type="text" title="Kommentar" name="comment" placeholder="Kommentar"></Input>
							<Input type="reset" defaultValue="Abbrechen" name=""></Input>
							<Input type="submit" defaultValue="Änderung anfragen" name=""></Input>
						</Form>
					</div>
				</Dialog>
				<Dialog ref={this.declineDriveRef} title="Fahrt-Anfrage ablehnen">
					<div className="change-request-dlg">
						<p>Bitte geben Sie einen Kommentar an, um die Fahrt abzulehnen.</p>
						<Form
							onSubmit={this.decline}
							onReset={() => {
								this.declineDriveRef.current?.close();
							}}
						>
							<Input type="text" title="Kommentar" name="comment" placeholder="Kommentar"></Input>
							<Input type="reset" defaultValue="Abbrechen" name=""></Input>
							<Input type="submit" defaultValue="Fahrt ablehnen" name=""></Input>
						</Form>
					</div>
				</Dialog>
				<Dialog ref={this.stornoDriveRef} title="Fahrt-Anfrage stornieren">
					<div className="change-request-dlg">
						<p>Bitte geben Sie einen Kommentar an, um die Fahrt zu stornieren.</p>
						<Form
							onSubmit={this.storno}
							onReset={() => {
								this.stornoDriveRef.current?.close();
							}}
						>
							<Input type="text" title="Kommentar" name="comment" placeholder="Kommentar"></Input>
							<Input type="reset" defaultValue="Abbrechen" name=""></Input>
							<Input type="submit" defaultValue="Fahrt stornieren" name=""></Input>
						</Form>
					</div>
				</Dialog>
				<div className="drive-forms">
					<Form ref={this.refCaller} title="1. Auftraggeber">
						<Input
							required
							readOnly={readonly}
							defaultValue={this.state.drive.callerName}
							name="callerName"
							title="Name"
							type="text"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={this.state.drive.callerTelephone ?? this.state.institution.telephone}
							name="callerTelephone"
							title="Tel. Nummer"
							type="tel"
						></Input>
						<Input
							required
							readOnly
							defaultValue={this.state.institution?.name}
							name="callerInstitution"
							title="Institution"
							type="text"
						></Input>
						<Input
							readOnly={readonly}
							defaultValue={this.state.drive.callerStation ?? this.state.institution.station}
							name="callerStation"
							title="Station / Institution"
							type="text"
						></Input>
					</Form>
					<Form ref={this.refPickup} title="2. Abhol-Informationen">
						<Input
							onClick={() => {
								this.openLocationDialog("pickup");
							}}
							readOnly={readonly}
							name=""
							defaultValue="Aus Liste auswählen"
							type="button"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.pickupCity
									? this.state.prefillOptions.pickupCity === "" || this.state.prefillOptions.pickupCity === undefined
										? this.state.drive.pickupCity
										: this.state.prefillOptions.pickupCity
									: this.state.prefillOptions.pickupCity ?? ""
							}
							name="pickupCity"
							title="Stadt"
							type="text"
							messagesAsTooltip
							maxWidth="200px"
						></Input>

						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.pickupPostcode
									? this.state.prefillOptions.pickupPostcode === "" || this.state.prefillOptions.pickupPostcode === undefined
										? this.state.drive.pickupPostcode
										: this.state.prefillOptions.pickupPostcode
									: this.state.prefillOptions.pickupPostcode ?? ""
							}
							name="pickupPostcode"
							title="PLZ"
							type="text"
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.pickupStreet
									? this.state.prefillOptions.pickupStreet === "" || this.state.prefillOptions.pickupStreet === undefined
										? this.state.drive.pickupStreet
										: this.state.prefillOptions.pickupStreet
									: this.state.prefillOptions.pickupStreet ?? ""
							}
							name="pickupStreet"
							title="Straße"
							type="text"
							messagesAsTooltip
							maxWidth="200px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.pickupHousenumber
									? this.state.prefillOptions.pickupHousenumber === "" || this.state.prefillOptions.pickupHousenumber === undefined
										? this.state.drive.pickupHousenumber
										: this.state.prefillOptions.pickupHousenumber
									: this.state.prefillOptions.pickupHousenumber ?? ""
							}
							name="pickupHousenumber"
							title="Hausnr."
							type="text"
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input
							readOnly={readonly}
							defaultValue={
								this.state.drive.pickupStation
									? this.state.prefillOptions.pickupStation === "" || this.state.prefillOptions.pickupStation === undefined
										? this.state.drive.pickupStation
										: this.state.prefillOptions.pickupStation
									: this.state.prefillOptions.pickupStation ?? ""
							}
							name="pickupStation"
							title="Station / Institution"
							type="text"
						></Input>
					</Form>
					<Form ref={this.refPatient} title="3. Patienten-Informationen">
						<Input
							required
							readOnly={readonly}
							defaultValue={this.state.drive.patientPrename}
							name="patientPrename"
							title="Vorname"
							type="text"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={this.state.drive.patientLastname}
							name="patientLastname"
							title="Nachname"
							type="text"
						></Input>
						<Input onClick={this.copyPickupToPatient} readOnly={readonly} name="" defaultValue="Abholort kopieren" type="button"></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.patientCity
									? this.state.prefillOptions.patientCity === "" || this.state.prefillOptions.patientCity === undefined
										? this.state.drive.patientCity
										: this.state.prefillOptions.patientCity
									: this.state.prefillOptions.patientCity ?? ""
							}
							name="patientCity"
							title="Stadt"
							type="text"
							messagesAsTooltip
							maxWidth="200px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.patientPostCode
									? this.state.prefillOptions.patientPostcode === "" || this.state.prefillOptions.patientPostcode === undefined
										? this.state.drive.patientPostCode
										: this.state.prefillOptions.patientPostcode
									: this.state.prefillOptions.patientPostcode ?? ""
							}
							name="patientPostCode"
							title="PLZ"
							type="text"
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.patientStreet
									? this.state.prefillOptions.patientStreet === "" || this.state.prefillOptions.patientStreet === undefined
										? this.state.drive.patientStreet
										: this.state.prefillOptions.patientStreet
									: this.state.prefillOptions.patientStreet ?? ""
							}
							name="patientStreet"
							title="Straße"
							type="text"
							messagesAsTooltip
							maxWidth="200px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.patientHousenumber
									? this.state.prefillOptions.patientHousenumber === "" ||
									  this.state.prefillOptions.patientHousenumber === undefined
										? this.state.drive.patientHousenumber
										: this.state.prefillOptions.patientHousenumber
									: this.state.prefillOptions.patientHousenumber ?? ""
							}
							name="patientHousenumber"
							title="Hausnr."
							type="text"
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={this.state.drive.patientWeight}
							name="patientWeight"
							title="Gewicht (kg)"
							type="number"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.noAdditionalPayment ? true : false}
							name="noAdditionalPayment"
							title="Zuzahlung Befreit"
							type="checkbox"
						></Input>
					</Form>
					<Form ref={this.refTarget} title="4. Ziel-Informationen">
						<Input
							onClick={() => {
								this.openLocationDialog("target");
							}}
							readOnly={readonly}
							name=""
							defaultValue="Aus Liste auswählen"
							type="button"
						></Input>
						<Input onClick={this.copyPatientToTarget} readOnly={readonly} defaultValue="Patientenadresse kopieren" type="button"></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.targetCity
									? this.state.prefillOptions.targetCity === "" || this.state.prefillOptions.targetCity === undefined
										? this.state.drive.targetCity
										: this.state.prefillOptions.targetCity
									: this.state.prefillOptions.targetCity ?? ""
							}
							name="targetCity"
							title="Stadt"
							type="text"
							messagesAsTooltip
							maxWidth="200px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.targetPostcode
									? this.state.prefillOptions.targetPostcode === "" || this.state.prefillOptions.targetPostcode === undefined
										? this.state.drive.targetPostcode
										: this.state.prefillOptions.targetPostcode
									: this.state.prefillOptions.targetPostcode ?? ""
							}
							name="targetPostcode"
							title="PLZ"
							type="text"
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.targetStreet
									? this.state.prefillOptions.targetStreet === "" || this.state.prefillOptions.targetStreet === undefined
										? this.state.drive.targetStreet
										: this.state.prefillOptions.targetStreet
									: this.state.prefillOptions.targetStreet ?? ""
							}
							name="targetStreet"
							title="Straße"
							type="text"
							messagesAsTooltip
							maxWidth="200px"
						></Input>
						<Input
							required
							readOnly={readonly}
							defaultValue={
								this.state.drive.targetHousenumber
									? this.state.prefillOptions.targetHousenumber === "" || this.state.prefillOptions.targetHousenumber === undefined
										? this.state.drive.targetHousenumber
										: this.state.prefillOptions.targetHousenumber
									: this.state.prefillOptions.targetHousenumber ?? ""
							}
							name="targetHousenumber"
							title="Hausnr."
							type="text"
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input
							readOnly={readonly}
							defaultValue={
								this.state.drive.targetStation
									? this.state.prefillOptions.targetStation === "" || this.state.prefillOptions.targetStation === undefined
										? this.state.drive.targetStation
										: this.state.prefillOptions.targetStation
									: this.state.prefillOptions.targetStation ?? ""
							}
							name="targetStation"
							title="Station / Institution"
							type="text"
						></Input>
					</Form>
					<Form ref={this.refTransport} title="5. Transport-Informationen">
						<MessageBox className="message" type="INFO">
							Eine Fahrt kann nur für den Zeitraum von {this.state.institution.timeFrom} Uhr und {this.state.institution.timeTo} Uhr
							angefragt werden.
						</MessageBox>
						<Input
							required
							readOnly={readonly}
							defaultValue={this.state.drive.transportationDate ?? ""}
							name="transportationDate"
							min={this.state.institution.timeFrom}
							max={this.state.institution.timeTo}
							title="Transportdatum (Abholzeit)"
							valueFormat={"DD.MM.YYYY HH:mm"}
							displayFormat={"DD.MM.YYYY HH:mm"}
							type="datetime-local"
						></Input>
						<Input
							readOnly={readonly}
							defaultValue={this.state.drive.possibleAppointment ?? ""}
							name="possibleAppointment"
							title="ggf. Termin"
							type="time"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.wheelchair ? true : false}
							name="wheelchair"
							title="Rollstuhl"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.ownWheelchair ? true : false}
							name="ownWheelchair"
							title="Eigener Rollstuhl"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.electroWheelchair ? true : false}
							name="electroWheelchair"
							title="Elektro-Rollstuhl"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.rollator ? true : false}
							name="rollator"
							title="Rollator"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.carryingChair ? true : false}
							name="carryingChair"
							title="Tragestuhl"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.ambulatory ? true : false}
							name="ambulatory"
							title="Ambulant"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.lying ? true : false}
							name="lying"
							title="Liegend"
							type="checkbox"
						></Input>
						<Input
							readOnly={readonly}
							defaultChecked={this.state.drive.stationaryAdmission ? true : false}
							name="stationaryAdmission"
							title="Stationäre Aufnahme"
							type="checkbox"
						></Input>
						<Input readOnly={readonly} defaultValue={this.state.drive.additional} name="additional" title="Kommentar" type="text"></Input>

						<Input
							required
							readOnly={readonly}
							name=""
							title="Ich bestätige, dass der Patient im unqualifizierten Krankentransport fahren darf, weil er frei von Infektionskrankheiten ist (negativ auf COVID-19!)
							keinen Sauerstoff oder andere medizinische Leistungen benötigt."
							type="checkbox"
						></Input>
					</Form>
				</div>
				{!this.props.driveId && (
					<div className="drive-submit">
						<Button type="error" text="Abbrechen" onClick={this.reset}></Button>
						<Button type="success" text="Speichern und Anfragen" onClick={this.props.onSubmit}></Button>
					</div>
				)}
				{this.props.driveId && !this.state.editMode && this.state.userType === "sachbearbeiter" && (
					<div className="drive-submit">
						{this.state.drive.state !== "declined" && (
							<Button
								type="error"
								text="Ablehnen"
								onClick={() => {
									this.declineDriveRef.current?.open();
								}}
							></Button>
						)}
						{this.state.drive.state !== "declined" && (
							<Button
								onClick={() => {
									this.requestChangeRef.current?.open();
								}}
								type="warning"
								text="Änderung Anfragen"
							></Button>
						)}

						{this.state.drive.state !== "accepted" && <Button type="success" text="Genehmigen" onClick={this.accept}></Button>}
						<IconButton
							type="transparent"
							icon="edit"
							onClick={() => {
								this.setState({ editMode: true });
							}}
						></IconButton>
					</div>
				)}
				{this.props.driveId && !this.state.editMode && this.state.userType === "institutionuser" && (
					<div className="drive-submit">
						{this.state.drive.state !== "declined" && this.state.drive.state !== "storno" && (
							<Button type="error" text="Stornieren" onClick={() => this.stornoDriveRef.current?.open()}></Button>
						)}

						<IconButton
							type="transparent"
							icon="edit"
							onClick={() => {
								this.setState({ editMode: true });
							}}
						></IconButton>
					</div>
				)}
				{this.props.driveId && this.state.editMode && (
					<div className="drive-submit">
						<Button type="error" text="Abbrechen" onClick={this.cancelEdit}></Button>
						<Button type="success" text="Speichern" onClick={this.update}></Button>
					</div>
				)}
			</div>
		);
	}
}
