import React, { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import DriveForm from "./DriveForm";
import "./RequestDrive.scss";

export interface RequestDriveProps {}

export interface RequestDriveState {}

export default class RequestDrive extends Component<RequestDriveProps, RequestDriveState> {
	driveForm = React.createRef<DriveForm>();

	submit = () => {
		if (this.driveForm.current?.validate()) {
			const values = this.driveForm.current?.getValues();

			values.institutionId = window.location.hash.replace("#", "");

			window.backend.postData("/drives", values, true).then(() => {
				this.driveForm.current?.reset();
			});
		}
	};

	render() {
		return (
			<BasePage title="Fahrt Anfragen">
				<DriveForm ref={this.driveForm} onSubmit={this.submit}></DriveForm>
			</BasePage>
		);
	}
}
