import { Cell, Dialog, Form, HeaderCell, Input, OwnTable, Spinner, TableBody, TableHeader, TableRow } from "@klumpp/tools";
import { Component } from "react";
import { Institution, User } from "../../../types/ObjectTypes";
import React from "react";
import BasePage from "../../dashboard/BasePage";

export interface UsersProps {}

export interface UsersState {
	users: User[];
	loading: boolean;
	addUserLoading: boolean;
	loadingUserInstitutions: boolean;
	userInstitutions: { all: Institution[]; user: Institution[] };
	permissionsUserType?: string;
	permissionUserLoading: boolean;
}

export const UserTypeOptions = [
	{ label: "Institutions-Mitarbeiter", value: "institutionuser" },
	{ label: "Administrator", value: "admin" },
	{ label: "Sachbearbeiter", value: "sachbearbeiter" },
];

export default class Users extends Component<UsersProps, UsersState> {
	state = {
		users: [],
		loading: true,
		addUserLoading: false,
		loadingUserInstitutions: false,
		userInstitutions: { all: [], user: [] },
		permissionsUserType: undefined,
		permissionUserLoading: true,
	} as UsersState;
	refUserDialog = React.createRef<Dialog>();
	refUserInstitutionsDialog = React.createRef<Dialog>();
	userInstitutionTable = React.createRef<OwnTable>();
	refPermissionUserDialog = React.createRef<Dialog>();

	componentDidMount() {
		this.loadUsers();
	}

	loadUsers = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData("/users", true).then((users) => {
				this.setState({ loading: false, users: users });
			});
		});
	};

	openAddUser = () => {
		this.refUserDialog.current?.open();
	};
	closeAddUser = () => {
		this.refUserDialog.current?.close();
	};

	loadUserInstitutions = (userId: string) => {
		this.setState({ loadingUserInstitutions: true }, () => {
			window.backend.getData(`/users/institutions/${userId}`, true).then((values) => {
				this.setState({ loadingUserInstitutions: false, userInstitutions: values });
			});
		});
	};

	addUser = (values: any, reset: any) => {
		this.setState({ addUserLoading: true }, () => {
			window.backend
				.postData("/users", values, true)
				.then(() => {
					this.closeAddUser();
					reset();
					this.loadUsers();
				})
				.finally(() => {
					this.setState({ addUserLoading: false });
				});
		});
	};

	deleteUser = (values: any[]) => {
		window.backend.deleteData("/users", { id: values[0] }, true).then(() => {
			this.loadUsers();
		});
	};

	institutionsUserId?: string;
	openUserInstitutionsDialog = (values: any[]) => {
		this.institutionsUserId = values[0];
		this.loadUserInstitutions(values[0]);
		this.refUserInstitutionsDialog.current?.open();
	};

	closeUserInstitutionsDialog = () => {
		this.institutionsUserId = undefined;
		this.refUserInstitutionsDialog.current?.close();
	};

	permissionsUserId?: string;
	openUserPermissionDialog = (values: any[]) => {
		this.permissionsUserId = values[0];
		this.setState({ permissionsUserType: values[1], permissionUserLoading: false }, () => {
			this.refPermissionUserDialog.current?.open();
		});
	};

	closeUserPermissionDialog = () => {
		this.permissionsUserId = undefined;
		this.refPermissionUserDialog.current?.close();
	};

	saveUserInstitutions = () => {
		const selected: number[] = this.userInstitutionTable.current?.getSelected().map((values) => values[0]) as number[];
		this.setState({ loadingUserInstitutions: true }, () => {
			window.backend
				.putData("/users/institutions", { selected: selected, userId: this.institutionsUserId, permissionUserLoading: true }, true)
				.then(() => {
					this.setState({ loadingUserInstitutions: false }, () => this.closeUserInstitutionsDialog());
				});
		});
	};

	changeUserPermission = (values: any, reset: any) => {
		window.backend.putData("/users/userType", { type: values.type, userId: this.permissionsUserId }, true).then(() => {
			reset();
			this.closeUserPermissionDialog();
			this.loadUsers();
		});
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;
		return (
			<BasePage title="Benutzer">
				<Dialog ref={this.refUserInstitutionsDialog} title="Benutzer Institutionen" closeable>
					{this.state.loadingUserInstitutions && <Spinner></Spinner>}
					{!this.state.loadingUserInstitutions && (
						<div>
							<OwnTable searchPlaceholder="Suchen..." ref={this.userInstitutionTable} selectType="MULTI">
								<TableHeader>
									<HeaderCell>Name</HeaderCell>
								</TableHeader>
								<TableBody>
									{this.state.userInstitutions.all.map((institution, index) => {
										const selected = this.state.userInstitutions.user.find(
											(userInstitution) => institution.id === userInstitution.id
										)
											? true
											: false;

										return (
											<TableRow key={index} defaultSelected={selected}>
												<Cell notVisible>{institution.id}</Cell>
												<Cell>{institution.name}</Cell>
											</TableRow>
										);
									})}
								</TableBody>
							</OwnTable>
							<Form onReset={this.closeUserInstitutionsDialog} onSubmit={this.saveUserInstitutions}>
								<Input type="reset" defaultValue="Abbrechen"></Input>
								<Input type="submit" defaultValue="Speichern"></Input>
							</Form>
						</div>
					)}
				</Dialog>
				<Dialog closeable ref={this.refPermissionUserDialog} title="Berechtigungen verwalten">
					{this.state.permissionUserLoading && <Spinner></Spinner>}
					{!this.state.permissionUserLoading && (
						<Form onReset={this.closeUserPermissionDialog} onSubmit={this.changeUserPermission}>
							<Input
								required
								defaultValue={this.state.permissionsUserType}
								name="type"
								type="select"
								title="Benutzer-Typ"
								placeholder="Auswählen"
								options={UserTypeOptions}
							></Input>

							<Input type="reset" defaultValue="Abbrechen"></Input>
							<Input type="submit" defaultValue="Ändern"></Input>
						</Form>
					)}
				</Dialog>
				<Dialog closeable ref={this.refUserDialog} title="Benutzer Erstellen">
					{this.state.addUserLoading && <Spinner></Spinner>}
					<Form onReset={this.closeAddUser} onSubmit={this.addUser}>
						<Input required name="email" type="email" placeholder="E-Mail" title="E-Mail"></Input>
						<Input required name="firstName" type="text" placeholder="Vorname" title="Vorname"></Input>
						<Input required name="lastName" type="text" placeholder="Nachname" title="Nachname"></Input>
						<Input required name="type" type="select" title="Benutzer-Typ" placeholder="Auswählen" options={UserTypeOptions}></Input>

						<Input type="reset" defaultValue="Abbrechen"></Input>
						<Input type="submit" defaultValue="Anlegen"></Input>
					</Form>
				</Dialog>
				<OwnTable
					searchPlaceholder="Suchen..."
					addButtonText="Anlegen"
					propertyButtons={[
						{ label: "Berechtigungen verwalten", onClick: this.openUserPermissionDialog },
						{ label: "Institutionen verwalten", onClick: this.openUserInstitutionsDialog },
						{ label: "Löschen", onClick: this.deleteUser },
					]}
					searchable
					onAdd={this.openAddUser}
				>
					<TableHeader>
						<HeaderCell>E-Mail</HeaderCell>
						<HeaderCell>Vorname</HeaderCell>
						<HeaderCell>Nachname</HeaderCell>
						<HeaderCell>Typ</HeaderCell>
					</TableHeader>
					<TableBody>
						{this.state.users.map((user, index) => {
							return (
								<TableRow key={index}>
									<Cell notVisible>{user.userId}</Cell>
									<Cell notVisible>{user.type}</Cell>
									<Cell>{user.email}</Cell>
									<Cell>{user.firstName}</Cell>
									<Cell>{user.lastName}</Cell>
									<Cell>{UserTypeOptions.find((option) => option.value === user.type)?.label}</Cell>
								</TableRow>
							);
						})}
					</TableBody>
				</OwnTable>
			</BasePage>
		);
	}
}
