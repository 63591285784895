import { Cell, HeaderCell, OwnTable, Form, Input, RouterHistory, showNotification, Spinner, TableBody, TableHeader, TableRow } from "@klumpp/tools";
import React, { Component } from "react";
import { Drive, UserType } from "../../../types/ObjectTypes";
import BasePage from "../../dashboard/BasePage";
import "./Drives.scss";

export interface DrivesProps {
	all?: boolean;
	archived?: boolean;
}

export interface DrivesState {
	userType: UserType;
	loading: boolean;
	drives: Drive[];
	institutionId: number;
	filter: {
		all: boolean;
		declined: boolean;
		accepted: boolean;
		storno: boolean;
		pending: boolean;
		change: boolean;
	};
}

export default class Drives extends Component<DrivesProps, DrivesState> {
	state = {
		loading: true,
		drives: [],
		institutionId: -1,
		userType: "",
		filter: {
			all: true,
			accepted: false,
			change: false,
			declined: false,
			pending: false,
			storno: false,
		},
	} as DrivesState;
	filterRef = {
		all: React.createRef<Input>(),
		declined: React.createRef<Input>(),
		accepted: React.createRef<Input>(),
		storno: React.createRef<Input>(),
		change: React.createRef<Input>(),
		pending: React.createRef<Input>(),
	};
	filterFormRef: React.RefObject<Form> = React.createRef<Form>();
	locListener: any;
	private markedStorageKey = "MARKEDDRIVES";
	componentDidMount() {
		window.backend.getData("/user/type", true).then((data) => {
			this.setState({ userType: data.type }, () => {
				const id = window.location.hash.replace("#", "");
				if (this.state.userType === "institutionuser") {
					this.setState({ institutionId: Number(id) }, () => {
						this.loadDrives();
					});
					this.locListener = RouterHistory.listen((location) => {
						const hash = window.location.hash.replace("#", "");
						const id = Number(hash === "" ? -1 : hash);
						if (this.state.institutionId !== id) {
							this.setState({ institutionId: id }, () => {
								this.loadDrives();
							});
						}
					});
				} else {
					this.loadDrives();
				}
			});
		});

		// Update Functions
		window.backend.socket()?.on("setDriveAsMarked", (id: number) => {
			const marked = this.getMarked();

			marked.push(id);
			window.localStorage.setItem(this.markedStorageKey, JSON.stringify(marked));
			this.forceUpdate();
			showNotification({ msg: "Eine Fahrt wurde aktualisiert!", type: "E" }, "bottom-left", false);
		});

		window.backend.socket()?.on("setDriveAccepted", (id: number) => {
			const drives = this.state.drives;
			const index = drives.findIndex((drive) => drive.id === id);
			if (index !== -1) drives[index].state = "accepted";

			this.setState({ drives: drives }, () => {
				showNotification({ msg: "Eine Fahrt wurde genehmigt!", type: "S" }, "bottom-left", false);
			});
		});
		window.backend.socket()?.on("setDriveDeclined", (id: number) => {
			const drives = this.state.drives;
			const index = drives.findIndex((drive) => drive.id === id);
			if (index !== -1) drives[index].state = "declined";

			this.setState({ drives: drives }, () => {
				showNotification({ msg: "Eine Fahrt wurde abgelehnt!", type: "E" }, "bottom-left", false);
			});
		});

		window.backend.socket()?.on("driveCreated", () => {
			this.loadDrives(false);
			showNotification({ msg: "Eine Fahrt wurde beantragt!", type: "W" }, "bottom-left", false);
		});

		window.backend.socket()?.on("driveUpdated", () => {
			this.loadDrives(false);
		});

		window.backend.socket()?.on("driveChangeRequested", () => {
			this.loadDrives(false);
		});
	}

	componentDidUpdate(props: DrivesProps) {
		if (props.all !== this.props.all) this.loadDrives();
	}

	componentWillUnmount() {
		this.locListener && this.locListener();

		window.backend.socket()?.off("setDriveAsMarked");
		window.backend.socket()?.off("setDriveAccepted");
		window.backend.socket()?.off("setDriveDeclined");
		window.backend.socket()?.off("driveCreated");
		window.backend.socket()?.off("driveUpdated");
		window.backend.socket()?.off("driveChangeRequested");
	}

	loadDrives = (loading: boolean = true, cb?: any) => {
		if (this.state.userType === "institutionuser" && this.state.institutionId !== -1) {
			this.setState({ loading: loading }, () => {
				window.backend.getData(`/drives/${this.state.institutionId}`, true).then((drives) => {
					this.setState({ loading: false, drives: drives }, cb);
				});
			});
		} else if (this.state.userType === "sachbearbeiter" && this.props.all) {
			this.setState({ loading: loading }, () => {
				window.backend.getData("/drives", true).then((drives) => {
					this.setState({ loading: false, drives: drives }, cb);
				});
			});
		} else if (this.state.userType === "sachbearbeiter" && this.props.archived) {
			this.setState({ loading: loading }, () => {
				window.backend.getData("/archivedDrives", true).then((drives) => {
					this.setState({ loading: false, drives: drives }, cb);
				});
			});
		}
	};

	onDriveClicked = (id: number) => {
		this.removeFromMarked(Number(id));
		RouterHistory.push(`/dashboard/drive?id=${id}`);
	};

	removeFromMarked = (id: number) => {
		let marked = this.getMarked();
		marked = marked.filter((mark) => mark !== id);
		window.localStorage.setItem(this.markedStorageKey, JSON.stringify(marked));
	};

	getMarked = (): any[] => {
		const markedObj = window.localStorage.getItem(this.markedStorageKey);

		return markedObj ? JSON.parse(markedObj) : ([] as any[]);
	};

	onFilterChanged = (values: any) => {
		if (this.state.filter.all === false && values.all === true) {
			values.storno = false;
			values.accepted = false;
			values.declined = false;
			values.change = false;
			values.pending = false;
		} else if (!values.accepted && !values.declined && !values.storno && !values.pending && !values.all && !values.change) {
			values.all = true;
		} else if (!(!values.accepted && !values.declined && !values.storno && !values.pending && !values.all && !values.change)) {
			values.all = false;
		}
		this.setState({ filter: values }, () => {
			this.filterFormRef.current?.resetInputs();
		});
	};

	render() {
		if (this.state.userType === "" || this.state.loading) return <Spinner></Spinner>;
		const marked = this.getMarked();
		let title = "Fahrtenübersicht";
		if (this.state.userType === "sachbearbeiter" && this.props.archived) title += " Archiv";

		let drivesAll = this.state.drives;
		let drives: any[] = [];
		if (this.state.userType === "sachbearbeiter") {
			if (this.state.filter.all) {
				drives = drivesAll;
			} else {
				if (this.state.filter.accepted) {
					drives = drives.concat(drivesAll.filter((drive) => drive.state === "accepted"));
				}

				if (this.state.filter.declined) {
					drives = drives.concat(drivesAll.filter((drive) => drive.state === "declined"));
				}

				if (this.state.filter.storno) {
					drives = drives.concat(drivesAll.filter((drive) => drive.state === "storno"));
				}

				if (this.state.filter.change) {
					drives = drives.concat(drivesAll.filter((drive) => drive.state === "changerequested"));
				}

				if (this.state.filter.pending) {
					drives = drives.concat(drivesAll.filter((drive) => drive.state === "pending"));
				}
			}
		} else {
			drives = drivesAll;
		}

		return (
			<BasePage title={title}>
				<div className="drives-overview">
					{this.props.all && (
						<div className="drives-filter">
							<h3>Filter:</h3>
							<div className="drives-filter-items">
								<Form ref={this.filterFormRef} onChange={this.onFilterChanged}>
									<Input
										ref={this.filterRef.all}
										defaultChecked={this.state.filter.all}
										type="checkbox"
										name="all"
										title="Alle"
									></Input>
									<Input
										ref={this.filterRef.accepted}
										defaultChecked={this.state.filter.accepted}
										type="checkbox"
										name="accepted"
										title="Akzeptiert"
									></Input>
									<Input
										ref={this.filterRef.declined}
										defaultChecked={this.state.filter.declined}
										type="checkbox"
										name="declined"
										title="Abgelehnt"
									></Input>
									<Input
										ref={this.filterRef.pending}
										defaultChecked={this.state.filter.pending}
										type="checkbox"
										name="pending"
										title="Ausstehend"
									></Input>
									<Input
										ref={this.filterRef.storno}
										defaultChecked={this.state.filter.storno}
										type="checkbox"
										name="storno"
										title="Storniert"
									></Input>
									<Input
										ref={this.filterRef.change}
										defaultChecked={this.state.filter.change}
										type="checkbox"
										name="change"
										title="Änderung"
									></Input>
								</Form>
							</div>
						</div>
					)}
					<div className="drives-table-wrapper">
						<OwnTable
							searchable
							searchPlaceholder="Suchen..."
							rowsMarkable
							onRowClicked={(values) => this.onDriveClicked(values[0])}
							clickable
						>
							<TableHeader>
								<HeaderCell>Anrufer</HeaderCell>
								<HeaderCell>Transportdatum</HeaderCell>
								<HeaderCell>Institution</HeaderCell>
								<HeaderCell>Abholort</HeaderCell>
								<HeaderCell>Abholstraße</HeaderCell>
								<HeaderCell>Abholstation</HeaderCell>
								<HeaderCell>Patient</HeaderCell>
								<HeaderCell>Status</HeaderCell>
							</TableHeader>
							<TableBody>
								{drives.map((drive, index) => {
									let state = "Abgelehnt";
									if (drive.state === "pending") state = "Ausstehend";
									if (drive.state === "accepted") state = "Genehmigt";
									if (drive.state === "changerequested") state = "Änderung angefragt";
									if (drive.state === "storno") state = "Storniert";
									const classes = ["drive-state-cell", drive.state].join(" ");
									return (
										<TableRow key={index} marked={marked.includes(drive.id)}>
											<Cell notVisible>{drive.id}</Cell>
											<Cell>{drive.callerName}</Cell>
											<Cell>{drive.transportationDate}</Cell>
											<Cell>{drive.name}</Cell>
											<Cell>{`${drive.pickupCity} ${drive.pickupPostcode}`}</Cell>
											<Cell>{drive.pickupStreet}</Cell>
											<Cell>{drive.pickupStation}</Cell>
											<Cell>{`${drive.patientPrename} ${drive.patientLastname}`}</Cell>
											<Cell>
												<div className={classes}>{state}</div>
											</Cell>
										</TableRow>
									);
								})}
							</TableBody>
						</OwnTable>
					</div>
				</div>
			</BasePage>
		);
	}
}
