import { Button, Cell, Dialog, Form, HeaderCell, Input, OwnTable, Spinner, TableBody, TableHeader, TableRow } from "@klumpp/tools";
import React, { Component } from "react";
import "./PrefillOptions.scss";
import BasePage from "../../dashboard/BasePage";
import { Institution, PrefillOption } from "../../../types/ObjectTypes";

export interface PrefillOptionsProps {}

export interface PrefillOptionsState {
	prefillOptions: PrefillOption[];
	optionsLoading: boolean;
	editOption?: PrefillOption;
	selectedInstitution: number;
	institutions: Institution[];
	loading: boolean;
}

export default class PrefillOptions extends Component<PrefillOptionsProps, PrefillOptionsState> {
	state = { prefillOptions: [], optionsLoading: true, selectedInstitution: -1, institutions: [], loading: true } as PrefillOptionsState;
	addDialogRef = React.createRef<Dialog>();
	editDialogRef = React.createRef<Dialog>();
	loadingDialogRef = React.createRef<Dialog>();
	refDialogDeletion = React.createRef<Dialog>();
	fileInputRef: React.RefObject<Input> = React.createRef<Input>();
	institutionInputRef: React.RefObject<Input> = React.createRef<Input>();

	loadPrefillOptions = (load: boolean = true) => {
		this.setState({ optionsLoading: load }, () => {
			window.backend.getData(`/institutions/driveFormPrefillOptionsAdmin/${this.state.selectedInstitution}`, true).then((options: any) => {
				this.setState({
					prefillOptions: options,
					optionsLoading: false,
				});
			});
		});
	};

	deleteAllLocations = () => {
		window.backend.deleteData(`/locations/${this.state.selectedInstitution}/all`, {}, true).then(() => {
			this.loadPrefillOptions(false);
			this.refDialogDeletion.current?.close();
		});
	};

	openEditDialog = (values: any) => {
		this.setState(
			{
				editOption: {
					institutionId: -1,
					field: values[0],
					value: values[1],
				},
			},
			() => {
				this.editDialogRef.current?.open();
			}
		);
	};

	createOption = (values: any, reset: any) => {
		values.institutionId = this.state.selectedInstitution;
		window.backend.postData(`/institutions/driveFormPrefillOptions/${this.state.selectedInstitution}`, values, true).then(() => {
			reset();
			this.loadPrefillOptions(false);
			this.addDialogRef.current?.close();
		});
	};

	updateOption = (values: any, reset: any) => {
		values.institutionId = this.state.selectedInstitution;
		window.backend.putData(`/institutions/driveFormPrefillOptions/${this.state.selectedInstitution}`, values, true).then(() => {
			reset();
			this.loadPrefillOptions(false);
			this.editDialogRef.current?.close();
		});
	};

	componentDidMount() {
		this.loadInstitutions();
	}

	deleteLocation = (values: any[]) => {
		window.backend.deleteData(`/locations/${values[0]}`, {}, true).then(() => {
			this.loadPrefillOptions(false);
		});
	};

	institutionChanged = () => {
		const value = this.institutionInputRef.current?.getValue();
		if (value !== "" && Number(value) !== this.state.selectedInstitution) {
			this.setState({ selectedInstitution: Number(value) }, () => {
				this.loadPrefillOptions();
			});
		}
	};

	loadInstitutions = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData("/institutions", true).then((institutions) => {
				this.setState({ loading: false, institutions });
			});
		});
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;

		return (
			<BasePage title="Orte">
				<Dialog ref={this.loadingDialogRef} title="Bitte warten, der Vorgang kann ein paar Minuten dauern.">
					<Spinner></Spinner>
				</Dialog>
				<Dialog ref={this.addDialogRef} title="Ort Anlegen" closeable>
					<Form
						onSubmit={this.createOption}
						onReset={() => {
							this.addDialogRef.current?.close();
						}}
					>
						<Input required title="Feld" type="text" name="field" placeholder="Feld"></Input>
						<Input required title="Wert" type="text" name="value" placeholder="Wert"></Input>

						<Input type="reset" defaultValue="Abbrechen"></Input>
						<Input type="submit" defaultValue="Speichern"></Input>
					</Form>
				</Dialog>
				<Dialog ref={this.editDialogRef} title="Ort Bearbeiten" closeable>
					{!this.state.editOption && <Spinner></Spinner>}
					{this.state.editOption && (
						<Form
							onSubmit={this.updateOption}
							onReset={() => {
								this.editDialogRef.current?.close();
							}}
						>
							<Input
								required
								defaultValue={this.state.editOption?.field}
								title="Feld"
								type="text"
								name="field"
								placeholder="Feld"
							></Input>
							<Input
								required
								defaultValue={this.state.editOption?.value}
								title="Wert"
								type="text"
								name="value"
								placeholder="Wert"
							></Input>
							<Input type="reset" defaultValue="Abbrechen"></Input>
							<Input type="submit" defaultValue="Speichern"></Input>
						</Form>
					)}
				</Dialog>
				<div className="locations">
					<div className="locations-institution-select">
						<Input
							required
							ref={this.institutionInputRef}
							onChange={this.institutionChanged}
							icon="keyboard_arrow_down"
							name="institution"
							type="select"
							defaultValue={this.state.selectedInstitution}
							options={this.state.institutions.map((institution) => {
								return { value: institution.id, label: institution.name };
							})}
							placeholder="Bitte wählen sie eine Institution aus."
						></Input>
					</div>
					<div className="locations-table">
						{this.state.selectedInstitution !== -1 && this.state.optionsLoading && <Spinner></Spinner>}
						{this.state.selectedInstitution !== -1 && !this.state.optionsLoading && (
							<OwnTable
								propertyButtons={[
									{ label: "Bearbeiten", onClick: this.openEditDialog },
									{ label: "Löschen", onClick: this.deleteLocation },
								]}
								searchable
								searchPlaceholder="Suchen..."
								addButtonText="Anlegen"
								noDataText="Keine Daten"
								onAdd={() => {
									this.addDialogRef.current?.open();
								}}
							>
								<TableHeader>
									<HeaderCell>Feld</HeaderCell>
									<HeaderCell>Wert</HeaderCell>
								</TableHeader>
								<TableBody>
									{this.state.prefillOptions.map((option, index) => {
										return (
											<TableRow key={index}>
												<Cell>{option.field}</Cell>
												<Cell>{option.value}</Cell>
											</TableRow>
										);
									})}
								</TableBody>
							</OwnTable>
						)}
					</div>
					<Dialog ref={this.refDialogDeletion} title="Löschen - Bestätigung">
						<p>Sollen wirklich alle Orte unwiederruflich gelöscht werden?</p>
						<div className="locations-delete-footer">
							<Button text="Abbrechen" onClick={() => this.refDialogDeletion.current?.close()} type="success"></Button>
							<Button text="Löschen" type="error" onClick={this.deleteAllLocations}></Button>
						</div>
					</Dialog>
					<div className="locations-footer">
						{this.state.selectedInstitution !== -1 && !this.state.optionsLoading && (
							<Button type="error" onClick={() => this.refDialogDeletion.current?.open()} text="Alle Löschen"></Button>
						)}
					</div>
				</div>
			</BasePage>
		);
	}
}
