import { LogPane, RouterHistory, showNotification } from "@klumpp/tools";
import { LogEntry } from "@klumpp/tools/dist/components/logger/LogPane";
import dayjs from "dayjs";
import React, { Component } from "react";
import BasePage from "../../dashboard/BasePage";

export interface LogsProps {}

export interface LogsState {}

/*
Filter (Nach Institution, User, Generelle Suche) Logs je nach Eintrag anklickbar (drei punkte am Ende)
				<p>Load on Scroll Neue Änderungen oben rein schreiben Nach ner Zeit Unten sachen rausnehmen</p>
*/
export default class Logs extends Component<LogsProps, LogsState> {
	logPaneRef = React.createRef<LogPane>();
	loadEntries = () => {
		return new Promise<LogEntry[]>((resolve, reject) => {
			window.backend
				.socket()
				?.emit("getLogEntries")
				.then((entries) => {
					const logs: LogEntry[] = [];
					entries.forEach((entry: any) => {
						logs.push(this.convertEntry(entry));
					});
					resolve(logs);
				});
		});
	};

	convertEntry = (entry: any, marked?: boolean) => {
		return {
			...entry,
			timestamp: dayjs(entry.timestamp),
			marked: marked,
			onClick: () => {
				RouterHistory.push(`/dashboard/drive?id=${entry.objectId}`);
			},
		};
	};

	componentDidMount = () => {
		window.backend.socket()?.on("addLogEntryToFrontend", (data) => {
			this.logPaneRef.current?.addEntry(this.convertEntry(data.log, true));
			showNotification({ msg: data.log.description, type: "E" }, "bottom-left", false);
		});
	};

	componentWillUnmount = () => {
		window.backend.socket()?.off("addLogEntryToFrontend");
	};

	render() {
		return (
			<BasePage title="Aktivitäts-Logs">
				<LogPane ref={this.logPaneRef} timestampFormat="DD.MM.YYYY HH:mm:ss" loadEntries={this.loadEntries} maxEntries={20}></LogPane>
			</BasePage>
		);
	}
}
