import React, { Component } from "react";

export interface BasePageProps {
	title: string;
}

export interface BasePageState {}

export default class BasePage extends Component<BasePageProps, BasePageState> {
	render() {
		return (
			<div className="basepage">
				<div className="basepage-title">{this.props.title}</div>
				<div className="basepage-content">{this.props.children}</div>
			</div>
		);
	}
}
