import React from "react";
import "./App.css";
import "@klumpp/tools/dist/klumpp-tools.css";
import { Auth, AuthCallback, BackendInterface, Cookie, CookieBanner, Logout, RouterHistory, ToastContainer } from "@klumpp/tools";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import "../styles/customize/dub.scss";

interface IAppState {
	auth: Auth;
}
class App extends React.Component<any, IAppState> {
	cookieBannerRef = React.createRef<CookieBanner>();
	constructor(props: any) {
		super(props);
		window.auth = new Auth(
			this.props.history,
			window.env.REACT_APP_AUTH0_DOMAIN as string,
			window.env.REACT_APP_AUTH0_CLIENT_ID as string,
			window.env.REACT_APP_AUTH0_CALLBACK_URL as string,
			window.env.REACT_APP_AUTH0_AUDIENCE as string
		);
		window.routerHistory = this.props.history;
		window.backend = new BackendInterface(
			window.auth,
			RouterHistory,
			window.env.REACT_APP_API_URL as string,
			{
				history: RouterHistory as any,
				errorPath: "/error",
			},
			window.env.REACT_APP_API_URL as string
		);

		window.cookies = new Cookie();
		window.cookies.setBannerRef(this.cookieBannerRef);
		this.state = {
			auth: window.auth,
		};
	}

	componentDidMount() {}

	render() {
		return (
			<div className="app">
				<ToastContainer />
				<CookieBanner
					acceptText="Akzeptieren"
					declineText="Ablehnen"
					ref={this.cookieBannerRef}
					title="Unsere Seite verwendet Cookies"
					cookieClass={window.cookies}
					history={RouterHistory}
				>
					Wir benutzen auf dieser Website Cookies, um dir Funktionen und Inhalte zur Verfügung zu stellen. Dafür brauchen wir deine
					Einwilligung. Mit einem Klick auf "Akzeptieren" gibst du uns deine Einwilligung, Cookies zu setzen.
				</CookieBanner>

				<Switch>
					<Route exact path="/" render={() => <Redirect to="/dashboard"></Redirect>}></Route>
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/callback" render={(props) => <AuthCallback auth={this.state.auth} />} />
					<Route path="/login" render={(props) => <Redirect to="/" />} />
					<Route path="/logout" render={(props) => <Logout to="/" history={RouterHistory} auth={window.auth} />} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
