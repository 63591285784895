import { Cell, Dialog, Form, HeaderCell, Input, OwnTable, Spinner, TableBody, TableHeader, TableRow } from "@klumpp/tools";
import { Component } from "react";
import React from "react";
import { Institution } from "../../../types/ObjectTypes";
import BasePage from "../../dashboard/BasePage";
import { Institution as InstitutionType } from "../../../types/ObjectTypes";

export interface InstitutionsProps {}

export interface InstitutionsState {
	institutions: Institution[];
	addInstitutionLoading: boolean;
	loading: boolean;
	editInstitution?: InstitutionType | undefined;
	editInstitutionLoading: boolean;
}

export default class Institutions extends Component<InstitutionsProps, InstitutionsState> {
	state = {
		institutions: [],
		loading: true,
		addInstitutionLoading: false,
		editInstitution: undefined,
		editInstitutionLoading: true,
	} as InstitutionsState;
	refInstitutionDialog = React.createRef<Dialog>();
	refEditInstitutionDialog = React.createRef<Dialog>();

	componentDidMount() {
		this.loadInstitutions();
	}

	loadInstitutions = () => {
		this.setState({ loading: true }, () => {
			window.backend.getData("/institutions", true).then((institutions) => {
				this.setState({ loading: false, institutions });
			});
		});
	};

	openAddInstitution = () => {
		this.refInstitutionDialog.current?.open();
	};
	closeAddInstitution = () => {
		this.refInstitutionDialog.current?.close();
	};

	addInstitution = (values: any, reset: any) => {
		this.setState({ addInstitutionLoading: true }, () => {
			window.backend
				.postData("/institutions", values, true)
				.then(() => {
					this.closeAddInstitution();
					reset();
					this.loadInstitutions();
				})
				.finally(() => {
					this.setState({ addInstitutionLoading: false });
				});
		});
	};

	deleteInstitution = (values: any[]) => {
		window.backend.deleteData(`/institutions/${values[0]}`, {}, true).then(() => {
			this.loadInstitutions();
		});
	};

	closeEditInstitution = () => {
		this.setState({ editInstitutionLoading: true, editInstitution: undefined }, () => {
			this.refEditInstitutionDialog.current?.close();
		});
	};

	openUpdateInstitution = (values: any[]) => {
		const institution = this.state.institutions.find((insti) => insti.id === Number(values[0]));
		if (institution) {
			this.refEditInstitutionDialog.current?.open();
			this.setState({ editInstitution: institution, editInstitutionLoading: false });
		}
	};

	updateInstitution = (values: any, reset: any) => {
		this.setState({ editInstitutionLoading: true }, () => {
			window.backend
				.putData(`/institutions/${this.state.editInstitution?.id}`, values, true)
				.then(() => {
					this.closeEditInstitution();
					reset();
					this.loadInstitutions();
				})
				.finally(() => {
					this.setState({ addInstitutionLoading: false });
				});
		});
	};

	render() {
		if (this.state.loading) return <Spinner></Spinner>;
		return (
			<BasePage title="Institutionen">
				<Dialog closeable ref={this.refEditInstitutionDialog} title="Institution aktualisieren">
					{this.state.editInstitutionLoading && <Spinner></Spinner>}
					{!this.state.editInstitutionLoading && (
						<Form onReset={this.closeEditInstitution} onSubmit={this.updateInstitution}>
							<Input
								required
								defaultValue={this.state.editInstitution?.name}
								name="name"
								type="text"
								placeholder="Name"
								title="Name"
							></Input>
							<Input
								required
								defaultValue={this.state.editInstitution?.city}
								name="city"
								type="text"
								placeholder="Stadt"
								title="Stadt"
								maxWidth="200px"
								messagesAsTooltip
							></Input>
							<Input
								required
								defaultValue={this.state.editInstitution?.plz}
								name="plz"
								type="text"
								placeholder="PLZ"
								title="PLZ"
								maxWidth="100px"
								messagesAsTooltip
							></Input>
							<Input
								required
								defaultValue={this.state.editInstitution?.street}
								name="street"
								type="text"
								placeholder="Straße"
								title="Straße"
								maxWidth="200px"
								messagesAsTooltip
							></Input>
							<Input
								required
								defaultValue={this.state.editInstitution?.housenumber}
								name="housenumber"
								type="text"
								placeholder="Hausnummer"
								title="Hausnr."
								maxWidth="100px"
								messagesAsTooltip
							></Input>
							<Input
								required
								defaultValue={this.state.editInstitution?.telephone}
								name="telephone"
								type="tel"
								placeholder="Telefonnummer"
								title="Telefonnr."
							></Input>
							<Input
								defaultValue={this.state.editInstitution?.station}
								name="station"
								type="text"
								placeholder="Station"
								title="Station"
							></Input>

							<Input
								required
								defaultValue={this.state.editInstitution?.timeFrom}
								name="timeFrom"
								type="time"
								placeholder="Zeit von"
								title="Zeit von"
								maxWidth="50%"
								messagesAsTooltip
							></Input>

							<Input
								required
								defaultValue={this.state.editInstitution?.timeTo}
								name="timeTo"
								type="time"
								placeholder="Zeit bis"
								title="Zeit bis"
								maxWidth="50%"
								messagesAsTooltip
							></Input>

							<Input type="reset" defaultValue="Abbrechen"></Input>
							<Input type="submit" defaultValue="Aktualisieren"></Input>
						</Form>
					)}
				</Dialog>
				<Dialog closeable ref={this.refInstitutionDialog} title="Institution Erstellen">
					{this.state.addInstitutionLoading && <Spinner></Spinner>}
					<Form onReset={this.closeAddInstitution} onSubmit={this.addInstitution}>
						<Input required name="name" type="text" placeholder="Name" title="Name"></Input>
						<Input required name="city" type="text" placeholder="Stadt" title="Stadt" messagesAsTooltip maxWidth="200px"></Input>
						<Input required name="plz" type="text" placeholder="PLZ" title="PLZ" messagesAsTooltip maxWidth="100px"></Input>
						<Input required name="street" type="text" placeholder="Straße" title="Straße" messagesAsTooltip maxWidth="200px"></Input>
						<Input
							required
							name="housenumber"
							type="text"
							placeholder="Hausnummer"
							title="Hausnr."
							messagesAsTooltip
							maxWidth="100px"
						></Input>
						<Input required name="telephone" type="tel" placeholder="Telefonnummer" title="Telefonnr."></Input>
						<Input name="station" type="text" placeholder="Station" title="Station"></Input>
						<Input
							required
							defaultValue={"07:00"}
							name="timeFrom"
							type="time"
							placeholder="Zeit von"
							title="Zeit von"
							maxWidth="50%"
							messagesAsTooltip
						></Input>

						<Input
							required
							defaultValue={"20:00"}
							name="timeTo"
							type="time"
							placeholder="Zeit bis"
							title="Zeit bis"
							maxWidth="50%"
							messagesAsTooltip
						></Input>

						<Input type="reset" defaultValue="Abbrechen"></Input>
						<Input type="submit" defaultValue="Anlegen"></Input>
					</Form>
				</Dialog>
				<OwnTable
					searchPlaceholder="Suchen..."
					addButtonText="Anlegen"
					searchable
					onAdd={() => this.openAddInstitution()}
					propertyButtons={[
						{ label: "Bearbeiten", onClick: this.openUpdateInstitution },
						{ label: "Löschen", onClick: this.deleteInstitution },
					]}
				>
					<TableHeader>
						<HeaderCell>Name</HeaderCell>
						<HeaderCell>Stadt</HeaderCell>
						<HeaderCell>Straße</HeaderCell>
						<HeaderCell>Hausnummer</HeaderCell>
						<HeaderCell>PLZ</HeaderCell>
					</TableHeader>
					<TableBody>
						{this.state.institutions?.map((institution) => (
							<TableRow>
								<Cell notVisible>{institution.id}</Cell>
								<Cell>{institution.name}</Cell>
								<Cell>{institution.city}</Cell>
								<Cell>{institution.street}</Cell>
								<Cell>{institution.housenumber}</Cell>
								<Cell>{institution.plz}</Cell>
							</TableRow>
						))}
					</TableBody>
				</OwnTable>
			</BasePage>
		);
	}
}
