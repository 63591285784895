import { Component } from "react";
import "./Setting.scss";

export interface SettingProps {
	title: string;
}

export interface SettingState {}

export default class Setting extends Component<SettingProps, SettingState> {
	render() {
		return (
			<div className="setting">
				<div className="setting-title">{this.props.title}</div>
				<div className="setting-content">{this.props.children}</div>
			</div>
		);
	}
}
