import { RouterHistory } from "@klumpp/tools";
import { Component } from "react";
import BasePage from "../../dashboard/BasePage";
import DriveForm from "./DriveForm";

export interface DetailDriveProps {}

export interface DetailDriveState {
	driveId: number;
}

export default class DetailDrive extends Component<DetailDriveProps, DetailDriveState> {
	state = { driveId: -1 };
	componentDidMount() {
		const id = Number(new URLSearchParams(window.location.search).get("id") ?? -1);
		if (id === -1) {
			const hash = window.location.hash.replace("#", "");
			RouterHistory.replace(`/dashboard/drives#${hash}`);
		}

		this.setState({ driveId: id });
	}
	render() {
		return (
			<BasePage title="Fahrt-Details">
				<DriveForm detail driveId={this.state.driveId}></DriveForm>
			</BasePage>
		);
	}
}
